<template>
  <sdModal
    centered
    type="primary"
    :title="contest?.attributes.title"
    :visible="visible"
    :onCancel="closeModal"
    :width="600"
  >
    <div>
      <div v-html="contest?.attributes.term" class="modal__text" />

      <div class="create-account__buttons">
        <sdButton size="large" :disabled="isLoading" type="danger" raised style="width: 49%" @click="closeModal">
          {{ i18n.t('contests.modal.cancel') }}
        </sdButton>
        <sdButton size="large" :disabled="isLoading" type="success" raised style="width: 49%" @click="perticipiate">
          {{ i18n.t('contests.modal.participate') }}
        </sdButton>
      </div>
    </div>
  </sdModal>
</template>

<script>
import { message } from 'ant-design-vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import helper from '@/config/helpers/helper';

const CreateTradingModal = {
  props: {
    contest: { type: Object },
    visible: {
      type: Boolean,
    },
  },
  emits: ['closeModal'],
  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const i18n = useI18n();
    const isLoading = computed(() => state.contests.loadingPerticipate);
    const error = computed(() => state.contests.error);

    const perticipiate = async () => {
      if (!props.contest.attributes.attempts_count) {
        closeModal(false);
        emit('update:buyTraitVisible', true);
      }

      await dispatch('perticipiateContest', { id: props.contest.id });

      if (error.value) {
        message.error(helper().errorValidator(error.value, true)[0]);
      } else {
        closeModal(true);
      }
    };

    const closeModal = (data) => {
      if (data) {
        dispatch('resetContestErrors');
      }
      emit('closeModal');
    };

    return {
      i18n,
      isLoading,
      closeModal,
      perticipiate,
    };
  },
};
export default CreateTradingModal;
</script>
<style scoped>
.modal__text {
  max-height: 300px;
  overflow: auto;
}
</style>
